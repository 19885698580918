@import '~vuetify/src/styles/styles.sass';
























































































































































































//@import '@design';
.disabled {
  color: grey;
  pointer-events: none;
}
.v-breadcrumbs {
  padding-bottom: 0;
}
.v-application ul,
.v-application ol {
  padding-left: 10px;
}
.stickyBanner {
  border: 1px solid #358345;
  background-color: var(--v-customBackground-base);
  position: sticky;
  top: 4rem;
  z-index: 2;
}
